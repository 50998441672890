import { IApiEurofilingFact, IApiFact } from "../api/types";
import { IExtractionState } from "../reducers/extractionReducer";
import { enumerationReferenceElementStyleValue } from "./constants";
import { handleReportFileCss } from "./handleReportFileCss";
import { handleReportInternalImages } from "./handleReportInternalImages";

export interface ReportMappingResponse {
  mappedHtml: string;
  reorderedFacts: Array<IApiFact | IApiEurofilingFact>;
  showStyleWarning: boolean;
}

const mapContinuations = (
  sourceFact: IApiFact,
  allElems: NodeListOf<Element>
) => {
  const continuationElems = Array.from(allElems).filter((elemm) =>
    sourceFact.continuationElementIds.includes(elemm.id)
  );
  for (const elem of continuationElems) {
    elem.setAttribute("associatedfactid", sourceFact.id);
  }
};

const getEurofilingFactByElement = (
  elem: Element,
  allFacts: IApiEurofilingFact[]
): IApiEurofilingFact | undefined => {
  const elemContextRef =
    elem.getAttribute("contextRef") || elem.getAttribute("contextref");
  const elemName = elem.getAttribute("name");
  const matchingFacts = allFacts.filter(
    (fact) =>
      fact.contextRefName === elemContextRef &&
      fact.factElementName === elemName
  );
  if (matchingFacts.length > 0) {
    if (matchingFacts.length > 1) {
      console.log(
        `found multiple matching facts for: ${elemContextRef} and ${elemName}. facts: ${matchingFacts
          .map((f) => f.id)
          .join(";")}`
      );
    }
    return matchingFacts[0];
  }
  return undefined;
};

const getFactByElement = (
  elem: Element,
  allFacts: IApiFact[],
  duplicateFactTrackingArray: { elemId: string; dupIndex: number }[]
): IApiFact | undefined => {
  const elemContextRef =
    elem.getAttribute("contextRef") || elem.getAttribute("contextref");
  const elemName = elem.getAttribute("name");
  const internalElemId = `${elemContextRef}_${elemName}`;
  const relevantFacts = allFacts.filter(
    (fact) =>
      fact.contextRef?.contextRef?.toLowerCase() ===
        elemContextRef?.toLowerCase() &&
      fact.contextRef?.name.toLowerCase() === elemName?.toLowerCase()
  );
  if (relevantFacts.length === 0) {
    // console.log(
    //   `could not find matching facts for: ${elemContextRef} and ${elemName}`
    // );
  } else if (relevantFacts.length > 1) {
    // console.log(
    //   `found multiple matching facts for: ${elemContextRef} and ${elemName}. facts: ${relevantFacts
    //     .map((f) => f.id)
    //     .join(";")}`
    // );
    console.log(internalElemId);
    const dupTracker = duplicateFactTrackingArray.find(
      (dft) => dft.elemId === internalElemId
    );
    if (dupTracker) {
      const index = dupTracker.dupIndex;
      dupTracker.dupIndex++;
      return relevantFacts[index];
    } else {
      duplicateFactTrackingArray.push({
        elemId: internalElemId,
        dupIndex: 1,
      });
      return relevantFacts[0];
    }
  } else {
    return relevantFacts[0];
  }
  return undefined;
};

const getFootnoteSource = (elem: Element, doc: Document): Element | null => {
  const relationships = Array.from(doc.querySelectorAll("relationship"));
  const relationship = relationships.find(
    (rel) =>
      rel.getAttribute("torefs")?.includes(elem.id) ||
      relationships.find((rel) => rel.getAttribute("toRefs")?.includes(elem.id))
  );
  const sourceElemId =
    relationship?.getAttribute("fromrefs") ||
    relationship?.getAttribute("fromRefs");
  return doc.querySelector(`#${sourceElemId}`);
};

export const getMappedReport = async (
  html: string,
  facts: Array<IApiFact | IApiEurofilingFact>,
  extractedFactsType: IExtractionState["factType"],
  reportId: string,
  orderStartingIndex: number,
  externalCss: string,
  images: {
    name: string;
    base64Value: string;
  }[]
): Promise<ReportMappingResponse> => {
  return new Promise((resolve, reject) => {
    try {
      const output: ReportMappingResponse = {
        mappedHtml: "",
        reorderedFacts: [],
        showStyleWarning: false,
      };
      //TODO: Check options to repress domparser errors
      const cspTag = window.document.querySelector("meta[property]");
      const noncTag = `<style nonce="${
        cspTag?.getAttribute("content") || ""
      }" `;
      html = html.replaceAll("<style ", noncTag);
      let doc = new DOMParser().parseFromString(html, "application/xhtml+xml");

      const parseError = doc.querySelectorAll("parsererror");
      if (parseError.length > 0) {
        doc = new DOMParser().parseFromString(html, "text/html");
      }

      const factReferenceElements = doc.querySelectorAll(
        `continuation, footnote, [contextRef], [contextref], [style*=${enumerationReferenceElementStyleValue}]`
      );
      const duplicateFactTrackingArray: { elemId: string; dupIndex: number }[] =
        [];

      const factIdsByOrderOfAppearence: string[] = [];
      if (extractedFactsType === "standard") {
        for (const factWithContinuations of (facts as IApiFact[]).filter(
          (f) => f.continuationElementIds?.length > 0
        )) {
          mapContinuations(factWithContinuations, factReferenceElements);
        }
      }
      factReferenceElements.forEach((elem) => {
        let fact: IApiFact | IApiEurofilingFact | undefined = undefined;
        let sourceFactElement = elem;
        if (
          (elem.getAttribute("contextRef") ||
            elem.getAttribute("contextref") ||
            elem
              .getAttribute("style")
              ?.includes(enumerationReferenceElementStyleValue)) &&
          elem.parentElement?.tagName !== "ix:hidden"
        ) {
          if (
            elem
              .getAttribute("style")
              ?.includes(enumerationReferenceElementStyleValue)
          ) {
            const referenceId = elem.getAttribute("style")?.split(":")[1];
            if (referenceId) {
              const sourceElement = doc.getElementById(referenceId);
              if (sourceElement) {
                sourceFactElement = sourceElement;
              }
            }
          }
          fact =
            extractedFactsType === "standard"
              ? getFactByElement(
                  sourceFactElement,
                  facts as IApiFact[],
                  duplicateFactTrackingArray
                )
              : getEurofilingFactByElement(elem, facts as IApiEurofilingFact[]);
          if (fact) {
            factIdsByOrderOfAppearence.push(fact.id);
            elem.setAttribute("associatedfactid", fact.id);
            if (extractedFactsType === "standard") {
              elem.setAttribute(
                "xbrlelementtype",
                (fact as IApiFact).factElement?.type?.split(":")[1]
              );
            }
          }
        }
        if (
          elem.tagName.includes("footnote") &&
          extractedFactsType === "standard"
        ) {
          const source = getFootnoteSource(elem, doc);
          if (source && !source.attributes.getNamedItem("associatedfactid")) {
            const sourceFact = getFactByElement(
              source,
              facts as IApiFact[],
              duplicateFactTrackingArray
            );
            elem.setAttribute("associatedfactid", sourceFact?.id || "");
          }
        }
      });
      output.reorderedFacts = structuredClone(facts).filter(
        (fact: IApiFact | IApiEurofilingFact) => {
          if (factIdsByOrderOfAppearence.includes(fact.id)) {
            fact.order =
              orderStartingIndex + factIdsByOrderOfAppearence.indexOf(fact.id);
            if ("reportId" in fact) {
              (fact as IApiFact).reportId = reportId;
            }
            return fact;
          }
          return false;
        }
      );
      const resp = handleReportFileCss(doc, externalCss);
      let updatedHtml = resp.html;
      updatedHtml = handleReportInternalImages(updatedHtml, images);
      output.mappedHtml = updatedHtml;
      output.showStyleWarning = resp.showStyleWarning;
      resolve(output);
    } catch (ex) {
      reject(ex);
    }
  });
};
