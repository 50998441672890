import { t } from "@lingui/macro";
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import { useMemo, useState } from "react";
import {
  ChangedFactDisplayItem,
  FactDisplayItem,
} from "../../helpers/mapFactItems";
import HtmlIcon from "@mui/icons-material/Html";
import { getSanitizedFactValue } from "../../helpers/getSanitizeReportHtml";
import CloseIcon from "@mui/icons-material/Close";
import { IApiFact } from "../../api/types";
import parse from "html-react-parser";
import { removeFromHtmlString } from "../../helpers/removeClassesFromHtmlString";

export interface FactHtmlValueDisplayProps {
  item: FactDisplayItem | ChangedFactDisplayItem | IApiFact;
  label?: string;
}

const FactHtmlValueDisplay = ({ item, label }: FactHtmlValueDisplayProps) => {
  const [showContentIFrame, setShowContentIFrame] = useState(false);
  const processedHtml = useMemo(() => {
    const sanitized = getSanitizedFactValue(
      removeFromHtmlString(item.formattedValue, true, false)
    );
    return parse(sanitized);
  }, [item.formattedValue]);

  const handleClick = (e: any) => {
    e.stopPropagation();
    setShowContentIFrame((prev) => !prev);
  };

  const displayLabel = "label" in item ? item.label : label;

  return (
    <>
      <Tooltip
        title={t`This fact has a value that's formatted in HTML. Click the icon to see`}>
        <HtmlIcon fontSize="large" onClick={handleClick} />
      </Tooltip>
      <Dialog
        maxWidth="lg"
        open={showContentIFrame}
        onClose={() => setShowContentIFrame(false)}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            {displayLabel}
            <IconButton
              aria-label="close"
              onClick={handleClick}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Box>{processedHtml}</Box>
      </Dialog>
    </>
  );
};

export default FactHtmlValueDisplay;
