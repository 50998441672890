import { Box, Container } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "../hooks/useAppSelector";
import { t } from "@lingui/macro";
import ContentHeaderToolbar from "../components/contentHeaderToolbar";
import { ToolbarActions } from "../helpers/constants";
import { getViewerToolbarActions } from "../helpers/getViewerToolbarActions";
import { IApiFact } from "../api/types";
import SelectLanguageMenu from "../components/selectLanguageMenu";
import GenerateStandaloneViewerDialog from "../components/viewer/generateStandaloneViewerDialog";
import ReportGenerator from "../components/viewer/reportGenerator";
import { useAppDispatch } from "../hooks/useAppDisplatch";
import {
  clearLifeCycleState,
  errorsByFactIdUpdated,
  factSelectionCallerUpdated,
  highlightAllFactsUpdated,
  highlightErrorsUpdated,
  selectedFactIdsUpdated,
} from "../reducers/internalLifeCycleReducer";
import { getEdgeDateFromFacts } from "../helpers/getEdgeDateFromFacts";
import { useComponentWillUnmount } from "../hooks/useComponentWillUnmount";
import { getCachedPackage } from "../helpers/getCachedPackage";
import { downloadReportFile } from "../helpers/downloadReportFile";
import { getInternalValidationEntriesForFacts } from "../helpers/getInternalValidationEntriesForFact";
import { mapValidationErrorsToFacts } from "../helpers/mapValidationErrorsToFacts";

export interface ViewerProps {
  isGeneralSidebarOpen?: boolean;
  currentGeneralSidebarDrawerWidth: number;
  onToggleSidebar?: () => void;
  allLanguages?: string[];
  currentSelectedLanguage?: string;
  onChangeLang?: (lang: string) => void;
}

const Viewer = ({
  isGeneralSidebarOpen,
  currentGeneralSidebarDrawerWidth,
  onToggleSidebar,

  allLanguages,
  currentSelectedLanguage,
  onChangeLang,
}: ViewerProps) => {
  const dispatch = useAppDispatch();
  const uploadedFileEnablesDebugView = useAppSelector(
    (state) =>
      state.import.uploadedFileNames.length === 1 &&
      state.import.uploadedFileNames[0]?.toLowerCase()?.includes(".epub")
  );
  const validationResult = useAppSelector(
    (state) => state.validate.validationResult
  );
  //eslint-disable-next-line react-hooks/exhaustive-deps
  const facts = useAppSelector((state) => state.extract.facts) || [];
  const extractedFactsType = useAppSelector((state) => state.extract.factType);
  const factsRef = useRef(facts);
  const selectedFactIds = useAppSelector(
    (state) => state.internalLifeCycle.selectedFactIds
  );
  const highlightAllFacts = useAppSelector(
    (state) => state.internalLifeCycle.highlightAllFacts
  );
  const highlightErrors = useAppSelector(
    (state) => state.internalLifeCycle.highlightErrors
  );
  const selectedFactIdsRef = useRef(selectedFactIds);
  const downloadedPayloadUrl = useAppSelector(
    (state) => state.import.downloadedPayloadUrl
  );
  const downloadedPayloadName = useAppSelector(
    (state) => state.import.downloadedPayloadName
  );

  const downloadedPayloadType = useAppSelector(
    (state) => state.import.downloadedPayloadType
  );
  const importId = useAppSelector((state) => state.import.importId);

  const reportIdentifier = useAppSelector((state) =>
    state.extract.factType === "standard"
      ? (state.extract.facts as IApiFact[]).find(
        (f) => f.contextRef?.context?.entity?.identifierValue
      )?.contextRef.context.entity.identifierValue
      : ""
  );

  const [currentZoomScale, setCurrentZoomScale] = useState(1);
  const [showDebugView, setShowDebugView] = useState(false);
  const [
    showDownloadStandaloneReportDialog,
    setShowDownloadStandaloneReportDialog,
  ] = useState(false);
  const [languageSelectMenuAnchorElement, setLanguageSelectMenuAnchorElement] =
    useState<null | HTMLElement>(null);

  useEffect(() => {
    factsRef.current = facts;
  }, [facts]);

  useEffect(() => {
    selectedFactIdsRef.current = selectedFactIds;
  }, [selectedFactIds]);

  const internalValidationErrors = useMemo(
    () => getInternalValidationEntriesForFacts(facts, extractedFactsType),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [facts.length, extractedFactsType]
  );

  useEffect(() => {
    if (facts.length > 0 && extractedFactsType === "standard") {
      const allValidationErros = [...internalValidationErrors];
      if (
        validationResult &&
        validationResult.entries &&
        validationResult.entries.length > 0
      ) {
        allValidationErros.push(...validationResult.entries);
      }

      dispatch(
        errorsByFactIdUpdated(
          mapValidationErrorsToFacts(facts as IApiFact[], allValidationErros)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    facts,
    validationResult,
    internalValidationErrors.length,
    extractedFactsType,
  ]);

  useComponentWillUnmount(() => dispatch(clearLifeCycleState(null)));

  const handleDownloadPlainViewerFile = async () => {
    const blob = await getCachedPackage(downloadedPayloadUrl);
    downloadReportFile(blob, downloadedPayloadName);
  };

  const handleToolbarIconClicked = (e: any, action: ToolbarActions) => {
    if (action === ToolbarActions.highlightFacts) {
      dispatch(highlightAllFactsUpdated(undefined));
    } else if (action === ToolbarActions.nextFact) {
      let lastFactIdSelected =
        selectedFactIdsRef.current.length > 0
          ? selectedFactIdsRef.current[0]
          : undefined;
      let currentIndex = -1;
      if (factsRef.current.find((fact) => fact.id === lastFactIdSelected)) {
        currentIndex = (
          factsRef.current.find(
            (fact) => fact.id === lastFactIdSelected
          ) as IApiFact
        ).order;
      }
      let nextIndex =
        currentIndex === factsRef.current.length - 1 ? 0 : currentIndex + 1;
      const nextFact = factsRef.current.find((f) => f.order === nextIndex);
      if (nextFact) {
        dispatch(selectedFactIdsUpdated(nextFact.id));
        dispatch(factSelectionCallerUpdated("viewer_toolbar"));
      }
    } else if (action === ToolbarActions.previousFact) {
      let lastFactIdSelected =
        selectedFactIdsRef.current.length > 0
          ? selectedFactIdsRef.current[0]
          : undefined;

      let currentIndexx = 0;
      if (factsRef.current.find((fact) => fact.id === lastFactIdSelected)) {
        currentIndexx = (
          factsRef.current.find(
            (fact) => fact.id === lastFactIdSelected
          ) as IApiFact
        ).order;
      }
      let nextIndexx =
        currentIndexx === 0 ? factsRef.current.length - 1 : currentIndexx - 1;
      const nextFact = factsRef.current.find((f) => f.order === nextIndexx);
      if (nextFact) {
        dispatch(selectedFactIdsUpdated(nextFact.id));
        dispatch(factSelectionCallerUpdated("viewer_toolbar"));
      }
    } else if (action === ToolbarActions.zoomIn) {
      setCurrentZoomScale((prev) => prev * 1.1);
    } else if (action === ToolbarActions.zoomOut) {
      setCurrentZoomScale((prev) => prev * 0.9);
    } else if (action === ToolbarActions.zoomReset) {
      setCurrentZoomScale(1);
    } else if (action === ToolbarActions.downloadReport) {
      if (facts.length === 0) {
        handleDownloadPlainViewerFile();
      } else {
        setShowDownloadStandaloneReportDialog(true);
      }
    } else if (action === ToolbarActions.highlightErrors) {
      dispatch(highlightErrorsUpdated(undefined));
    } else if (action === ToolbarActions.showSidebar) {
      onToggleSidebar && onToggleSidebar();
    } else if (action === ToolbarActions.selectLanguage) {
      setLanguageSelectMenuAnchorElement(e.currentTarget);
    } else if (action === ToolbarActions.showDebugView) {
      setShowDebugView((prev) => !prev);
    }
  };

  const currentToolbarActions = useMemo(
    () =>
      getViewerToolbarActions(
        handleToolbarIconClicked,
        !validationResult &&
        !internalValidationErrors.some((e) => e.isHighlightable),
        facts.length > 0,
        allLanguages || [],
        currentZoomScale,
        highlightAllFacts,
        highlightErrors,
        (facts.length === 0 &&
          uploadedFileEnablesDebugView) ||
        false,
        showDebugView,
        extractedFactsType
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      facts.length,
      allLanguages?.length,
      currentZoomScale,
      highlightAllFacts,
      highlightErrors,
      showDebugView,
      validationResult,
      internalValidationErrors.length,
    ]
  );

  return (
    <>
      <Container
        maxWidth={isGeneralSidebarOpen ? false : "xl"}
        sx={{
          pt: 10,
          ml: isGeneralSidebarOpen ? 0 : "auto",
          width: {
            xl: isGeneralSidebarOpen
              ? `calc(100% - ${currentGeneralSidebarDrawerWidth}px)`
              : "100%",
            lg: isGeneralSidebarOpen
              ? `calc(100% - ${currentGeneralSidebarDrawerWidth}px)`
              : "90%",
            md: isGeneralSidebarOpen
              ? `calc(100% - ${currentGeneralSidebarDrawerWidth}px)`
              : "90%",
          },
        }}
        component="div">
        <Box>
          <ContentHeaderToolbar
            title={t`Viewer`}
            actions={currentToolbarActions}
            showIcons
          />
          <ReportGenerator
            isMinimal={false}
            currentZoomScale={currentZoomScale}
            highlightAllFacts={highlightAllFacts}
            highlightErrors={highlightErrors}
            showDebugView={showDebugView}
          />
        </Box>
      </Container>
      <SelectLanguageMenu
        allLangs={allLanguages || []}
        anchorElement={languageSelectMenuAnchorElement}
        onCloseMenu={() => setLanguageSelectMenuAnchorElement(null)}
        onSelectLang={onChangeLang}
        selectedLang={currentSelectedLanguage || ""}
      />
      <GenerateStandaloneViewerDialog
        open={showDownloadStandaloneReportDialog}
        onClose={() => setShowDownloadStandaloneReportDialog(false)}
        packageUrl={downloadedPayloadUrl}
        packageName={downloadedPayloadName}
        packageType={downloadedPayloadType}
        importId={importId}
        reportEndDate={getEdgeDateFromFacts(facts, extractedFactsType, false)}
        reportIdentifier={reportIdentifier || ""}
      />
    </>
  );
};

export default Viewer;
