import { PropsWithChildren } from "react";
import { Navigate } from "react-router";
import { useAppSelector } from "../hooks/useAppSelector";

const UserIsParseportMember = ({ children }: PropsWithChildren) => {
  const currentUser = useAppSelector((state) => state.manageUsers.currentUser);

  return (
    <>
      {currentUser?.organizationId === process.env.REACT_APP_ParseportOrgId ? (
        <>{children}</>
      ) : (
        <>
          <Navigate to="/import" replace={true} />
        </>
      )}
    </>
  );
};

export default UserIsParseportMember;
