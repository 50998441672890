/* Mui */
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { useAppSelector } from "../hooks/useAppSelector";
import { ValidationSummary } from "../components/summaryComponents/validationSummaryComponents/validationSummary";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { GeneralInformation } from "../components/summaryComponents/generalInformation";
import { TaxonomyPackage } from "../components/summaryComponents/taxonomyPackage";
import { ZipContent } from "../components/summaryComponents/validationSummaryComponents/zipContent";
import { ValidationLog } from "../components/summaryComponents/generaicList";
import { allCheckers } from "../helpers/validationCheckers";
import { ErrorShowCase } from "../components/summaryComponents/errorShowCase";
import { useEffect, useMemo, useState } from "react";

import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import {
  downloadSummaryFileNameSuffix,
  entryPointsCheckErrorShowcase,
  importErrors,
  linkBaseCheckErrorShowcase,
  taxonomyCheckErrorShowcase,
  ToolbarActions,
  validationSummaryRootElementId,
  xHTMLCheckErrorShowcase,
  XMLCheckErrorShowcase,
} from "../helpers/constants";
import ContentHeaderToolbar from "../components/contentHeaderToolbar";
import { ContentHeaderToolbarAction } from "../helpers/getFactsToolbarActions";
import { t } from "@lingui/macro";
import { getCachedPackage } from "../helpers/getCachedPackage";
import { getReportFilesAsFileFromPackage } from "../helpers/getReportFileAsHtml";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import { getPlainXbrlFile } from "../api/summary";
import { downloadReportFile } from "../helpers/downloadReportFile";
import { getInternalValidationEntriesForFact } from "../helpers/getInternalValidationEntriesForFact";
import { ErrorAlertCard } from "../components/summaryComponents/errorAlertCard";
import { changeConversionErrorShowcaseDialog } from "../reducers/conversionReducer";
import { useAppDispatch } from "../hooks/useAppDisplatch";
import { AppDispatch } from "../store";
import { GetImportStatus } from "../helpers/getImportStatus";
import { getSheetsForSummaryExcelExport } from "../helpers/getSheetsForSummaryExcelExport";
import ExcelExportDialog from "../components/excelExportDialog";
import { downloadPageAsPdf } from "../helpers/downloadPageAsPdf";
import { IApiFact } from "../api/types";

export const SummaryComponent = () => {
  const importId = useAppSelector((state) => state.import.importId);
  const downloadedPayloadUrl = useAppSelector(
    (state) => state.import.downloadedPayloadUrl
  );
  const downloadedPayloadName = useAppSelector(
    (state) => state.import.downloadedPayloadName
  );

  const downloadedPayloadType = useAppSelector(
    (state) => state.import.downloadedPayloadType
  );
  const extractedBasicData = useAppSelector(
    (state) => state.extract.extractedBasicData
  );

  const reportFileHashCode = useAppSelector(
    (state) => state.extract.reportFileHashCode
  );

  const dispatch: AppDispatch = useAppDispatch();

  const conversionErrors = useAppSelector(
    (state) => state.convert.conversionErrors
  );

  const conversionErrorShowcaseDialog = useAppSelector(
    (state) => state.convert.conversionErrorShowcaseDialog
  );

  const conversionErrorDialogAccepted = useAppSelector(
    (state) => state.convert.conversionErrorDialogAccepted
  );

  const validationResult = useAppSelector(
    (state) => state.validate.validationResult
  );
  const facts = useAppSelector((state) => state.extract.facts);
  const extreactedFactsType = useAppSelector((state) => state.extract.factType);

  const allcheckersSaved = useMemo(
    () => allCheckers(validationResult),
    [validationResult]
  );

  const internalValidaitonErrors = useMemo(
    () =>
      extreactedFactsType === "standard"
        ? (facts as IApiFact[])
          .map((fact, _, facts) =>
            getInternalValidationEntriesForFact(
              fact,
              facts,
              extreactedFactsType
            )
          )
          .flat()
        : [],
    [facts, extreactedFactsType]
  );

  const conversionFailed = useAppSelector(
    (state) => state.convert.conversionFailed
  );

  const factsExtractionError = useAppSelector(
    (state) => state.extract.factsExtractionError
  );

  const basicDataExtractionError = useAppSelector(
    (state) => state.extract.basicDataExtractionError
  );

  const generateError = useAppSelector((state) => state.generate.generateError);

  const validationError = useAppSelector(
    (state) => state.validate.validationError
  );

  const downloadConvertedPayloadError = useAppSelector(
    (state) => state.convert.downloadConvertedPayloadError
  );

  const importStatus = GetImportStatus(
    conversionFailed,
    validationError,
    factsExtractionError,
    basicDataExtractionError,
    generateError,
    downloadConvertedPayloadError
  );

  const handleDownload = () => {
    downloadPageAsPdf(
      validationSummaryRootElementId,
      `${downloadedPayloadName}_${downloadSummaryFileNameSuffix}.pdf`
    );
  };

  const toolbarIconClickHandler = (e: any, action: ToolbarActions) => {
    if (action === ToolbarActions.downloadSummary) {
      handleDownload();
    } else if (action === ToolbarActions.downloadPlainXbrl) {
      handlePlainXbrl();
    } else if (action === ToolbarActions.downloadZip) {
      handleDownloadZip();
    } else if (action === ToolbarActions.download) {
      setShowExcelExportDialog(true);
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [errorShowcase, setErrorShowcase] = useState<String>("");
  const [showExcelExportDialog, setShowExcelExportDialog] = useState(false);

  const headerToolbarActions: ContentHeaderToolbarAction[] = [
    {
      id: ToolbarActions.download,
      title: t`Download summary as excel sheet`,
      icon: DownloadOutlinedIcon,
      clickAction: (e) => toolbarIconClickHandler(e, ToolbarActions.download),
      disabled: downloadedPayloadType === "viewerFile",
      turnedOn: false,
    },
    {
      id: ToolbarActions.downloadSummary,
      title: t`Download summary as pdf document`,
      icon: DownloadOutlinedIcon,
      clickAction: (e) =>
        toolbarIconClickHandler(e, ToolbarActions.downloadSummary),
      disabled: false,
      turnedOn: false,
    },
    {
      id: ToolbarActions.downloadPlainXbrl,
      title: "Download plain Xbrl file",
      icon: SimCardDownloadIcon,
      clickAction: (e) =>
        toolbarIconClickHandler(
          e,
          downloadedPayloadType === "viewerFile" && facts.length === 0
            ? ToolbarActions.downloadZip
            : ToolbarActions.downloadPlainXbrl
        ),
      disabled: extreactedFactsType === "eurofiling",
      turnedOn: false,
      loading: isLoading,
    },
    {
      id: ToolbarActions.downloadZip,
      title:
        downloadedPayloadType === "package"
          ? t`Download zip`
          : t`Download xhtml file`,
      icon: FolderZipIcon,
      clickAction: (e) =>
        toolbarIconClickHandler(e, ToolbarActions.downloadZip),
      disabled: downloadedPayloadType === "",
      turnedOn: false,
    },
  ];

  useEffect(() => {
    if (errorShowcase !== "") {
      setTimeout(() => {
        setErrorShowcase("");
      }, 5000);
    }
  }, [errorShowcase]);

  async function handlePlainXbrl() {
    setIsLoading(true);
    const getBlobFile = await getCachedPackage(downloadedPayloadUrl);
    let file = undefined;
    if (getBlobFile.type === "application/xhtml+xml") {
      file = new File([getBlobFile], downloadedPayloadName, {
        type: "xhtml/html",
      });
    } else {
      const fileFromPackage =
        await getReportFilesAsFileFromPackage(getBlobFile);
      file = fileFromPackage[0];
    }
    const formData = new FormData();
    formData.append("file", file);
    const getPlainXbrl = await getPlainXbrlFile(importId, formData);
    try {
      const url = window.URL.createObjectURL(getPlainXbrl);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", importId + ".xbrl");
      document.body.appendChild(link);
      link.click();
      link.remove();
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      setErrorShowcase("Error while downloading plain xbrl file");
    }
  }

  const handleDownloadZip = async () => {
    const blob = await getCachedPackage(downloadedPayloadUrl);
    downloadReportFile(blob, downloadedPayloadName);
  };

  useEffect(() => {
    if (conversionErrorDialogAccepted) {
      return;
    }
    if (conversionErrors.length === 0) {
      dispatch(changeConversionErrorShowcaseDialog(true));
      return;
    }
    if (conversionErrors.length > 0 && !conversionErrorDialogAccepted) {
      dispatch(changeConversionErrorShowcaseDialog(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversionErrors]);

  return (
    <>
      <Dialog open={!conversionErrorShowcaseDialog}>
        <DialogTitle>{t`Conversion Errors`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t`There are conversion errors in this report, which may affect validation. They should be addressed first.`}
          </DialogContentText>
          <DialogActions>
            <Button
              onClick={() => {
                dispatch(changeConversionErrorShowcaseDialog(true));
              }}>
              Ok
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Container
        maxWidth="xl"
        sx={{ mt: 8, mb: 10 }}
        id={validationSummaryRootElementId}>
        <ContentHeaderToolbar
          title=""
          actions={headerToolbarActions}
          showIcons
          marginTop={10}
        />
        {importStatus === "dialog" && (
          <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            {ErrorAlertCard(true, "dialog")}
          </Grid>
        )}
        <Grid container spacing={2}>
          {errorShowcase !== "" && (
            <Grid item xs={12}>
              <Alert severity="error">{errorShowcase}</Alert>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <ValidationSummary
              internalTaxonomyValidationEntries={internalValidaitonErrors.map(
                (entry) => ({
                  ...entry,
                  message: Array.isArray(entry.message)
                    ? entry.message
                    : [entry.message],
                })
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GeneralInformation
              Name={extractedBasicData?.packageName || ""}
              Id={facts[0]?.id}
              hashCode={
                extractedBasicData?.reportingPackageContent?.hashCode ||
                reportFileHashCode ||
                ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            {conversionErrors.length > 0 && (
              <>
                <Grid item xs={12} sm={12} sx={{ mb: 2 }}>
                  <ValidationLog
                    header={"Conversion Errors"}
                    alteredText={conversionErrors}
                    alteredTextColor={"red"}
                  />
                </Grid>
              </>
            )}
            <ErrorShowCase
              title={importErrors}
              data={internalValidaitonErrors}
              shouldCombine={true}
            />
            <ErrorShowCase
              title={taxonomyCheckErrorShowcase}
              data={allcheckersSaved.TaxonomyCheck}
              shouldCombine={false}
            />
            <ErrorShowCase
              title={XMLCheckErrorShowcase}
              data={allcheckersSaved.xmlCheck}
              shouldCombine={false}
            />
            <ErrorShowCase
              title={linkBaseCheckErrorShowcase}
              data={allcheckersSaved.linkBaseCheck}
              shouldCombine={false}
              showCalcInconsistencyToggle
            />
            <ErrorShowCase
              title={xHTMLCheckErrorShowcase}
              data={allcheckersSaved.xHTMLCheck}
              shouldCombine={false}
            />
            <ErrorShowCase
              title={entryPointsCheckErrorShowcase}
              data={allcheckersSaved.entryPointsCheck}
              shouldCombine={false}
            />
            <ErrorShowCase
              title={t`Internal validation`}
              data={allcheckersSaved.internalValidationErrors}
              shouldCombine={false}
            />
            <Grid item xs={12} sm={12} sx={{ mb: 2 }}>
              {extractedBasicData && (
                <TaxonomyPackage basicData={extractedBasicData} />
              )}
            </Grid>
            {extractedBasicData && (
              <Grid item xs={12} sm={12} sx={{ mb: 2 }}>
                <ZipContent packageUrl={downloadedPayloadUrl} />
              </Grid>
            )}
            <Grid item xs={12} sm={12} sx={{ mb: 2 }}>
              <ValidationLog
                header={"Validation Log"}
                validationResult={validationResult || undefined}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <ExcelExportDialog
        actionBody={{
          sheets: getSheetsForSummaryExcelExport(
            validationResult,
            validationSummaryRootElementId
          ),
        }}
        actionUrl={`api/exports/excel?returnFileName=${downloadedPayloadName.split(".")[0]
          }_${downloadSummaryFileNameSuffix}.xlsx`}
        open={showExcelExportDialog}
        onClose={() => setShowExcelExportDialog(false)}
        title={t`Exporting...`}
      />
    </>
  );
};
